
<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >miscellaneous addenda
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title">
            automated electronic payment of rent
          </label>
          <div class="form-row">
            <TextField
              name="residentName"
              label="resident name"
              class="ml-0"
            />
            <TextField
              name="portionOfRent"
              label="portion of rent"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <TextField
              name="residentName2"
              class="ml-0"
            />
            <TextField
              name="portionOfRent2"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <TextField
              name="residentName3"
              class="ml-0"
            />
            <TextField
              name="portionOfRent3"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <TextField
              name="residentName4"
              class="ml-0"
            />
            <TextField
              name="portionOfRent4"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <TextField
              name="residentName5"
              class="ml-0"
            />
            <TextField
              name="portionOfRent5"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <TextField
              name="residentName6"
              class="ml-0"
            />
            <TextField
              name="portionOfRent6"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row text-title">special provisions</div>
          <div class="form-row">
            <text-input
              rows="5"
              id="specialProvisions"
              v-model="specialProvisions"
              multiline
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"> enclosed garage </label>
          <div class="form-row text-title">
            resident is entitled to exclusive possesion of
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="garageAttachedToDwelling"
              />
            </button>
            <div class="text-title">
              garage or carport attached to the dwelling
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="garageSpaceNumber" />
            </button>
            <div class="text-title mr-4">garage space no(s)</div>
            <TextField
              name="garageSpaceNumberText"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="carportSpaceNumber" />
            </button>
            <div class="text-title mr-4">carport space no(s)</div>
            <TextField
              name="carportSpaceNumberText"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row text-title ml-4">and/or</div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="storageUnitNumber" />
            </button>
            <div class="text-title mr-4">storage unit no(s)</div>
            <TextField
              name="storageUnitNumberText"
              class="ml-0"
            />
          </div>
          <div class="form-row flex-wrap">
            <div class="text-title">resident</div>
            <div class="form-row" style="margin-bottom: 0">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="willHaveGarageOpenerOrKey"
                />
              </button>
              <div class="text-title">will</div>
            </div>
            <div class="form-row" style="margin-bottom: 0">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="willNotHaveGarageOpenerOrKey"
                />
              </button>
              <div class="text-title mr-4">will not</div>
            </div>
            <div class="text-title">be provided with a</div>
            <div class="form-row" style="margin-bottom: 0">
              <button>
                <CheckboxInputSmall type="checkbox" name="garageOpener" />
              </button>
              <div class="text-title mr-4">garage door opener</div>
            </div>
            <div class="form-row text-title" style="margin-bottom: 0">
              and/or
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="garageKey"
                  style="margin-bottom: 0"
                />
              </button>
              <div class="text-title">garage key</div>
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">
              not returning garage opener will result in a fine of
            </div>
            <TextInputSmall name="garageOpenerFine" type="number" />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">legal description</label>
          <div class="form-row text-title">legal description of unit</div>
          <div class="form-row">
            <text-input
              id="legalDescriptionOfUnit"
              v-model="legalDescriptionOfUnit"
              rows="5"
              multiline
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">move-out notice</label>
          <div class="form-row">
            <TextInputSmall
              type="number"
              name="moveOutNotice"
              class="ml-0"
            />
            <div class="text-title">
              days move-out notice must be given for month-to-month leases
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">no-smoking</label>
          <div class="form-row text-title">
            smoking on balconies, patios, and limited common areas attached to
            or outside of your apartmnet
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="canSmoke" />
            </button>
            <div class="text-title">is</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="canNotSmoke" />
            </button>
            <div class="text-title mr-4">is not</div>
            <div class="text-title">permitted</div>
          </div>
          <div class="form-row text-title">
            the following outside areas of the community may be used for smoking
          </div>
          <div class="form-row">
            <text-input
              id="smokingArea"
              v-model="smokingArea"
              rows="5"
              multiline
            />
          </div>
          <div class="form-row flex-wrap">
            <div class="text-title">smoking must be at least</div>
            <TextInputSmall type="number" name="smokingDistance" />
            <div class="text-title">
              feet from the buildings in the apartment community
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >patio or yard maintenance</label
          >
          <div class="form-row">
            <div class="text-title">
              who will keep the lawn mowed and edged?
            </div>
            <button>
              <CheckboxInputSmall type="checkbox" name="lawnMowedYou" />
            </button>

            <div class="text-title">you</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="lawnMowedUs" />
            </button>

            <div class="text-title">us</div>
          </div>
          <div class="form-row">
            <div class="text-title">
              who will water the lawn and other vegetation?
            </div>
            <button>
              <CheckboxInputSmall type="checkbox" name="waterLawnYou" />
            </button>

            <div class="text-title">you</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="waterLawnUs" />
            </button>

            <div class="text-title">us</div>
          </div>
          <div class="form-row">
            <div class="text-title">
              who will keep the lawn free of trash and debris?
            </div>
            <button>
              <CheckboxInputSmall type="checkbox" name="keepLawnCleanYou" />
            </button>

            <div class="text-title">you</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="keepLawnCleanUs" />
            </button>

            <div class="text-title">us</div>
          </div>
          <div class="form-row">
            <div class="text-title">who is obligated to fertilize?</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="fertilizeYou" />
            </button>

            <div class="text-title">you</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="fertilizeUs" />
            </button>

            <div class="text-title">us</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="fertilizeNeither" />
            </button>

            <div class="text-title">neither</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">satellite dish</label>
          <div class="form-row">
            <div class="text-title">you may install</div>
            <TextInputSmall type="number" name="installedDishes" />
            <div class="text-title">
              satellite dish(es) or antenna(s) on the leased premises
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">the insurance coverage must be</div>
            <TextInputSmall
              type="number"
              name="insuranceCoverage"
              class="small-input"
            />
          </div>
          <div class="form-row">
            <div class="text-title">
              your security deposit is increased by an additional sum of
            </div>
            <TextInputSmall type="number" name="securityDeposit" />
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="securityDepositNotInstalled"
              />
            </button>
            <div class="text-title">not effective until installation or</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                class="mr-2 w-4 h-4"
                name="securityDepositInstalled"
              />
            </button>
            <div class="text-title">due within</div>
            <TextInputSmall type="number" name="securityDepositInstalledText" />
            <div class="text-title">days of installation</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >trash removal/recycling - flat fee</label
          >
          <div class="form-row">
            <div class="text-title">late charge</div>
            <TextInputSmall type="number" name="trashLateCharge" />
            <div class="text-title">(not to exceed $3)</div>
          </div>
          <div class="form-row">
            <div class="text-title">flat fee for trash/recycling costs</div>
            <TextInputSmall type="number" name="feeForTrash" />
          </div>
          <div class="form-row flex-wrap">
            <div class="text-title">an administrative fee of</div>
            <TextInputSmall type="number" name="administrativeFee" />
            <div class="text-title">
              per month (not to exceed $3) will be added to your bill
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">carrying handguns onsite</label>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="handgun.option1"
                class="ml-0"
              />
            </button>
            <div class="text-title">
              option 1: a person may not enter this property with a concealed
              handgun
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="handgun.option2"
                class="ml-0"
              />
            </button>
            <div class="text-title">
              option 2: a person may not enter this property with a handgun that
              is carried openly
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="handgun.option3"
                class="ml-0"
              />
            </button>
            <div class="text-title">option 3: a person may not enter</div>
          </div>
          <div class="form-row" style="margin-left: 1rem">
            <button>
              <CheckboxInputSmall type="checkbox" name="handgun.option3A" />
            </button>
            <div class="text-title">the leasing office or</div>
          </div>
          <div class="form-row" style="margin-left: 1rem">
            <button>
              <CheckboxInputSmall type="checkbox" name="handgun.option3B" />
            </button>
            <div class="text-title">
              any common room/amenities of this property with a concealed
              handgun
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="handgun.option4"
                class="ml-0"
              />
            </button>
            <div class="text-title">option 4: a person may not enter</div>
          </div>
          <div class="form-row" style="margin-left: 1rem">
            <button>
              <CheckboxInputSmall type="checkbox" name="handgun.option4A" />
            </button>
            <div class="text-title">the leasing office or</div>
          </div>
          <div class="form-row" style="margin-left: 1rem">
            <button>
              <CheckboxInputSmall type="checkbox" name="handgun.option4B" />
            </button>
            <div class="text-title">
              any common room/amenities of this property with a handgun that is
              carried openly
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >short-term lease addendum</label
          >
          <div class="form-row">
            <text-input
              id="shortTermTextarea"
              v-model="shortTermTextarea"
              rows="5"
              multiline
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">washing machine addendum</label>
          <div class="form-row text-title">
            residents have permission to install and use:
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="canInstallWasher" />
            </button>
            <div class="text-title">a washing machine and/or</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="canInstallDryer" />
            </button>
            <div class="text-title">a dryer</div>
          </div>
        </div>
      </FinalForm>
    </div>
    <modal-footer :footer="footer" :primary="saveData"></modal-footer>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import TextInputSmall from "@/components/form/TextInputSmall";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    FinalForm,
    ModalFooter,
    TextField,
    CheckboxInputSmall,
    TextInputSmall,
    TextInput,
  },
  computed: {
    initialValues() {
      return {
        loading: false,
        footer: {
          primaryButton: "save",
        },
        residentName: "",
        portionOfRent: "",
        residentName2: "",
        portionOfRent2: "",
        residentName3: "",
        portionOfRent3: "",
        residentName4: "",
        portionOfRent4: "",
        residentName5: "",
        portionOfRent5: "",
        residentName6: "",
        portionOfRent6: "",
        specialProvisions: "",
        garageAttachedToDwelling: "",
        garageSpaceNumber: "",
        garageSpaceNumberText: "",
        carportSpaceNumber: "",
        carportSpaceNumberText: "",
        storageUnitNumber: "",
        storageUnitNumberText: "",
        willHaveGarageOpenerOrKey: "",
        willNotHaveGarageOpenerOrKey: "",
        garageOpener: "",
        garageKey: "",
        garageOpenerFine: "",
        legalDescriptionOfUnit: "",
        moveOutNotice: "",
        canSmoke: "",
        canNotSmoke: "",
        smokingArea: "",
        smokingDistance: "",
        lawnMowedYou: "",
        lawnMowedUs: "",
        waterLawnYou: "",
        waterLawnUs: "",
        keepLawnCleanYou: "",
        keepLawnCleanUs: "",
        fertilizeYou: "",
        fertilizeUs: "",
        fertilizeNeither: "",
        installedDishes: "",
        insuranceCoverage: "",
        securityDeposit: "",
        securityDepositNotInstalled: "",
        securityDepositInstalled: "",
        securityDepositInstalledText: "",
        trashLateCharge: "",
        feeForTrash: "",
        administrativeFee: "",
        handgun: {
          option1: "",
          option2: "",
          option3: "",
          option3A: "",
          option3B: "",
          option4: "",
          option4A: "",
          option4B: "",
        },
        shortTermTextarea: "",
        canInstallWasher: "",
        canInstallDryer: "",
      };
    },
  },
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      residentName: "",
      portionOfRent: "",
      residentName2: "",
      portionOfRent2: "",
      residentName3: "",
      portionOfRent3: "",
      residentName4: "",
      portionOfRent4: "",
      residentName5: "",
      portionOfRent5: "",
      residentName6: "",
      portionOfRent6: "",
      specialProvisions: "",
      garageAttachedToDwelling: "",
      garageSpaceNumber: "",
      garageSpaceNumberText: "",
      carportSpaceNumber: "",
      carportSpaceNumberText: "",
      storageUnitNumber: "",
      storageUnitNumberText: "",
      willHaveGarageOpenerOrKey: "",
      willNotHaveGarageOpenerOrKey: "",
      garageOpener: "",
      garageKey: "",
      garageOpenerFine: "",
      legalDescriptionOfUnit: "",
      moveOutNotice: "",
      canSmoke: "",
      canNotSmoke: "",
      smokingArea: "",
      smokingDistance: "",
      lawnMowedYou: "",
      lawnMowedUs: "",
      waterLawnYou: "",
      waterLawnUs: "",
      keepLawnCleanYou: "",
      keepLawnCleanUs: "",
      fertilizeYou: "",
      fertilizeUs: "",
      fertilizeNeither: "",
      installedDishes: "",
      insuranceCoverage: "",
      securityDeposit: "",
      securityDepositNotInstalled: "",
      securityDepositInstalled: "",
      securityDepositInstalledText: "",
      trashLateCharge: "",
      feeForTrash: "",
      administrativeFee: "",
      handgun: {
        option1: "",
        option2: "",
        option3: "",
        option3A: "",
        option3B: "",
        option4: "",
        option4A: "",
        option4B: "",
      },
      shortTermTextarea: "",
      canInstallWasher: "",
      canInstallDryer: "",
    };
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvisions =
        this.specialProvisions;
      this.$refs.form.formState.values.smokingArea = this.smokingArea;
      this.$refs.form.formState.values.legalDescriptionOfUnit =
        this.legalDescriptionOfUnit;
      this.$refs.form.formState.values.shortTermTextarea =
        this.shortTermTextarea;
    },
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
